.reason-for-approval {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  
  .reason-header-wrapper {
    display: flex;
    flex-direction: column;
  }

  .page-title {
    padding-bottom: 20px;
  }

  .reason-input {
    width: 100%;
  }

  .mt-16 {
    margin-top: 20px;
  }
}
