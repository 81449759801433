@import "./constants/constants.scss";
$header-grey: #706b69;

.data-catalog-search-drawer {
  .owc-workflow-overlay__backdrop-element__overlay-element {
    position: absolute;
    right: 0;
    border: none;
    width: $drawer-width !important;
  }

  .owc-card__content-wrapper {
    overflow-x: hidden;
  }

  .owc-component-header__prefix-container div[slot="prefix"] {
    width: 100%;
  }

  .owc-card__content-wrapper .owc-workflow-overlay__slot-wrapper {
    height: 100%;
  }

  owc-component-header[slot="header"].owc-workflow-overlay__header-wrapper {
    padding: 0.5rem 0.5rem 0.5rem 1rem !important;
  }

  owc-component-header {
    padding: 0 !important;
  }

  div[slot="content"] {
    padding: 0 !important;
    margin: 0px !important;
    width: $drawer-width;
  }

  .header {
    width: $content-width;
    display: flex;
    justify-content: space-between;

    span {
      display: block;
      color: $header-grey;
      font-weight: 500;
      padding-bottom: 5px;
    }

    .close-icon-container {
      background-color: transparent;
      border: none;

      &:hover {
        cursor: pointer;
      }

      owc-icon {
        color: $header-grey !important;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
  }

  .footer {
    display: flex;
    height: $footer-height;
    justify-content: space-between;
    border: none;
    border-top: 2px solid #d3d3d3;
  }
}
