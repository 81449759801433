.custom-tooltip {
  padding: 6px;
  display: block;
  z-index: 10000000000000000;
  background: #312f2e;
  color: var(--one-color-accent-white);
  margin: 15px;
  position: absolute;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  border-radius: 4px;
  white-space: nowrap;
}