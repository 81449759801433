// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $starcap-primary: mat.define-palette(mat.$indigo-palette);
// $starcap-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
// $starcap-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $starcap-theme: mat.define-light-theme((
//   color: (
//     primary: $starcap-primary,
//     accent: $starcap-accent,
//     warn: $starcap-warn,
//   )
// ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($starcap-theme);

@use "@angular/material" as mat;
@include mat.core();

$theme-primary: mat.define-palette(mat.$indigo-palette);
$theme-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$theme-warn: mat.define-palette(mat.$red-palette);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: mat.define-typography-config(),
  )
);

@include mat.all-component-themes($theme);

/* You can add global styles to this file, and also import other style files */
@import "~@one/roche-font/roche-font.css";
@import "~@one/web-components/dist/owc/owc.css";
@import "~@one/icons/dist/one-icons-outlined.css";
@import '~@one/icons/dist/one-icons-filled.css';
// Also you can use legacy dark theme, cobas light theme, cobas dark theme or navify light theme
@import "~@one/design-tokens/css/cobas/light/variables.css";
@import "../projects/datacatalog/src/styles.scss";

//colour token variables
$white: var(--one-color-accent-white);
$blue-500: var(--one-color-blue-500);
$gray-500: var(--one-color-gray-500);
$gray-50: var(--one-color-gray-50);
$gray-600-li-n4: var(--one-color-gray-600);
$green-600-sb: var(--one-color-subbrand-600);
$blue-600: var(--one-color-blue-600);
$black-high-contrast: #21201f;
$light-bg-neutral3: #312f2e;

html,
body {
  margin: 0px;
  padding: 0px;
}

//Breadcrumb

.owc-breadcrumb {
  display: flex;
  padding: 16px;
  align-items: center;

  .owc-hyperlink__anchor__default-slot-wrapper {
    text-decoration: none !important;

    .owc-typography {
      color: $blue-600;
    }
  }

  .owc-breadcrumb-item .owc-breadcrumb-item__icon {
    color: $blue-600;
  }

  .owc-breadcrumb-item--disabled {
    .owc-typography {
      color: $gray-500 !important;
    }
  }
}

//Overview Page
.overview-layout {
  .header-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    .page-title-main {
      color: var(--light-background-neutral-3, $light-bg-neutral3);

      font-weight: 300!important;
    }
  }

  //Search box div
  .table-search-div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    margin-top: 32px;
    margin-bottom: 24px;

    .owc-input.owc-input--size-small {
      height: 36px;
      min-height: 36px;
    }

    .owc-button button {
      padding-top: 6px;
      padding-bottom: 6px;
      color: $white;
    }

    .owc-icon.owc-icon--not-loaded {
      width: 0.2em !important;
      color: #000 !important;
    }
  }

  //Search result div
  .search-result-div {
    padding-bottom: 24px;
  }

  //overview table
  .owc-table {
    .owc-card {
      border: none;
    }

    .owc-table-header {
      background-color: $gray-50;
    }

    .owc-table-header-cell {
      min-height: unset;
      padding-top: 3px;
      padding-bottom: 3px;
      justify-content: flex-start;

      .owc-skeleton-loader {
        background-color: #dbd6d1; //token not available in kit
        width: 400px !important;
      }
    }

    //table header label wrapper
    .owc-table-header-cell.owc-table-header-cell--valign-top
      > .owc-table-header-cell__slot-wrapper {
      display: flex;
      max-width: fit-content;
      height: fit-content;
      padding: 5px 12px 5px 0px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .owc-typography {
        color: $gray-600-li-n4;
      }
    }

    //sort arrows
    .owc-table-header-cell
      > .owc-table-header-cell__suffix-wrapper
      > .owc-table-header-cell__suffix-wrapper__sorting-icon {
      margin-left: 0px;
      color: $gray-500;
    }

    .owc-table-cell {
      min-height: 42px;
      padding-top: unset;
      padding-bottom: unset;
      align-items: center;

      .owc-typography {
        color: $gray-600-li-n4;
        text-align: center;
      }

      .owc-skeleton-loader {
        background-color: $gray-50;
        width: 400px !important;
      }

      //hyperlink in table content
      .owc-hyperlink__anchor__default-slot-wrapper {
        color: $light-bg-neutral3; //color not available as token in owc kit.

        .hyperlink-div {
          max-width: 430px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .status-badge-div {
      display: flex;
      justify-content: center;
      width: 150px;
      margin-left: -8px;
    }

    //Expanded Text
    .owc-table-row.owc-table-row--expanded > .owc-table-row__expanded-wrapper {
      color: $gray-600-li-n4;
    }
  }

  .nodata-innerdiv {
    min-width: 450px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    align-self: center;
    margin-top: 154px;
    margin-bottom: 154px;
  }

  //footer on overview page
  .owc-component-footer {
    padding-right: unset;

    .owc-pagination {
      color: $gray-500 !important;
      padding: unset;
      gap: 16px !important;

      .owc-input .owc-input__input-wrapper input {
        color: $gray-500 !important;
      }
      .owc-icon-button {
        color: $gray-500 !important;
        --content-padding: unset;
        min-height: 12px;
        min-width: 12px;
      }

      .owc-icon-button--disabled {
        opacity: 50%; //color token not given
      }
    }

    .owc-component-footer__default-slot-wrapper {
      flex-direction: row-reverse;
      justify-content: space-between !important;
    }
  }
}

//overlay scroll bar without impacting the width
.owc-drawer > .owc-drawer__content-wrapper {
  scrollbar-gutter: stable;
}

// Side Menu config
.menuColMode {
  width: 3.5rem !important;
}
.menuExpMode {
  width: 12rem !important;
}
.mr10 {
  margin-right: 10px;
}
.owc-modal-dialog.owc-modal-dialog--size-small {
  .owc-component-footer > .owc-component-footer__default-slot-wrapper {
    justify-content: flex-end !important;
    gap: 20px;
  }
  .owc-card {
    max-width: 38.75rem !important;
  }
}

//input error icon
.owc-input.owc-input--validity-error
  .owc-input__input-wrapper__prefix-container__error-icon {
  display: none;
}

.data-catalog-search-datepicker
  .owc-input.owc-input--validity-error
  .owc-input__input-wrapper__prefix-container
  [slot="prefix"] {
  display: flex;
}

//remove previous input error icon setting
.custom-select-dropdown-wrapper
  .owc-input.owc-input--validity-error
  .owc-input__input-wrapper__prefix-container__error-icon {
  display: inline-block !important;
  color: var(--one-color-red-400) !important;
}

//form field assertive-text
.owc-input .owc-input__information-container [slot="assistive-text"] {
  padding-right: unset;
}

.animated-loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

//Spinner
.loading-modal-spinner {
  z-index: 9999;
  background: $white;
  opacity: 0.8;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  bottom: 0px;
  text-align: center;
}
// .owc-progress-spinner {
//   position: relative;
//   top: 50%;
// }
.owc-input .owc-input__input-wrapper input {
  text-overflow: ellipsis;
}
.owc-card {
  width: 100%;
}
.owc-backdrop {
  z-index: 99999;
}

.myapp-details {
  .viewFiles-div {
    .owc-button button {
      padding: unset;
    }
  }
  .owc-component-footer {
    background-color: transparent;
  }
  .owc-component-footer > .owc-component-footer__default-slot-wrapper {
    justify-content: flex-start !important;
    gap: var(--one-spacer-px-32) !important;
    background-color: transparent;
  }
  .approval-drawer.owc-drawer--placement-right.owc-drawer--mode-over
    > .owc-drawer__panel-wrapper {
    position: fixed;
    z-index: 999999;
  }
  .approval-drawer > .owc-drawer__backdrop {
    position: fixed !important;
  }
  .approval-drawer .owc-drawer__content-wrapper {
    padding: 0px !important;
  }
}

.owc-table .owc-table-header {
  z-index: 0 !important;
}
.toast-container {
  z-index: 9999999 !important;
}
.owc-input .owc-input__information-container__remaining-count {
  padding-right: unset !important;
}

.toolTip {
  .owc-tooltip__popover {
    width: auto !important;
    text-wrap: nowrap !important;
  }
}

.upload_progress_list {
  .mat-line {
    display: flex;
  }
  .owc-grid-item {
    align-items: center;
  }
}

/* .owc-icon.owc-icon--not-loaded {
  color: #000 !important;
} */

.owc-snackbar {
  width: 600px;
  .owc-banner.owc-banner--variant-secondary
    > .owc-banner__top-wrapper
    > .owc-banner__top-wrapper__top-left-wrapper
    > .owc-banner__top-wrapper__top-left-wrapper__prefix-wrapper
    owc-icon {
    margin: auto;
    width: 50%;
  }
}

.owc-table-header {
  .owc-table-header-cell {
    &:hover {
      background-color: transparent;
    }
  }
}
.owc-table-row {
  &:hover {
    background-color: transparent;
  }
  .owc-icon-button {
    &:hover {
      background-color: transparent;
    }
  }
}

.cdk-overlay-container {
  z-index: 1000000000000000;
}

.ngx-file-drop{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 211px;
  border-radius: 0px !important;
  height: auto !important;
  padding: 10px;
  margin-left: 0px !important;
}

.ngx-file-drop__drop-zone {
  border-radius: 0 !important;
  height: auto !important;
  border: 1px solid #C2BAB5 !important;
  padding: 2px;
  width: 100% !important;
  margin-left: 0px !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}


@mixin generate-margins($property, $values) {
  @each $value in $values {
    .#{$property}-#{$value} {
      margin-top: #{$value}px;
    }
  }
}

$margin-values: 4, 8, 12, 16, 24, 32, 48;

@include generate-margins('mt', $margin-values);
