.cursor-pointer {
  cursor: pointer;
}

owc-table-cell owc-typography:not(.cursor-pointer) {
  cursor: default;
}

.owc-table-cell__content-container {
  // width: 100%;

  .owc-typography {
    text-align: left !important;
  }

  .text-ellipsis {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.description{
  word-break: break-word;
}
