@import "~@one/web-components/dist/owc/owc.css";

$gray-50: var(--one-color-gray-50);
$light-grey-accent: #fbfbfa;
.data-files-preview {
  .files-info {
    margin: 0.5rem 0.5rem 0.5rem 1rem;
  }

  .breadcrumbs {
    background-color: $light-grey-accent;
  }

  .breadcrumbs-placeholder {
    height: 27.8px;
    background-color: $light-grey-accent;
  }

  .owc-table {
    owc-card {
      border: none;
    }

    owc-typography {
      color: #544f4f;
    }

    .owc-table-header {
      background-color: $gray-50;

      .owc-table-cell {
        text-wrap: nowrap;
        text-align: center;
      }
    }

    .owc-table-cell {
      text-wrap: nowrap;
      text-align: center;
    }

    .owc-table-row {
      text-wrap: nowrap;
    }

    .folders-container {
      .name-cell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 330px;
      }

      .folder {
        font-weight: bold;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
      }

      .folder:hover,
      .folder-icon:hover {
        cursor: pointer;
      }
      owc-icon {
        color: #706b69;
      }
    }
  }
}
