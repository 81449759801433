::ng-deep .review-dataset-drawer {
  .owc-workflow-overlay__backdrop-element__overlay-element {
    position: absolute;
    right: 0;
    width: 600px !important;
  }

  .owc-component-header__prefix-container div[slot="prefix"] {
    width: 100%;
  }

  .owc-card__content-wrapper .owc-workflow-overlay__slot-wrapper {
    height: auto;
  }

  owc-component-header[slot="header"].owc-workflow-overlay__header-wrapper {
    padding: 0.5rem 0.5rem 0.5rem 1rem !important;
  }

  owc-component-header {
    padding: 0 !important;
  }

  div[slot="content"] {
    padding: 0 !important;
  }

  .content-container {
    padding: 0.5rem 0.5rem 0.5rem 1rem;

    .owc {
      &-input {
        width: 100%;
        margin-top: 16px;
      }

      &-grid {
        margin-top: var(--one-spacer-px-16);

        &-item {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .label {
      color: #706b69;
    }

    .data-classification {
      owc-input {
        background-color: var(--one-color-gray-50) !important;
      }

      .owc-icon.owc-icon--not-loaded {
        color: inherit !important;
      }
    }
  }

  .owc-component-footer[slot="footer"] {
    justify-content: space-between;

    .owc-component-footer__prefix-slot-wrapper div[slot="prefix"] {
      display: flex;
      gap: 24px;
    }

    .owc-component-footer__suffix-slot-wrapper div[slot="suffix"] {
      display: flex;
      gap: 24px;
    }
  }

  .footer-border-bottom {
    border-top: 1px solid var(--one-color-background-intensity-medium);
  }
}

.review-dataset-drawer-loader {
  position: absolute;
  top: 50vh;
  left: 30vh;
}

.content-container {
  .review-dataset-drawer-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
