.category-list {
  &__item-content {
    display: flex;
    justify-content: space-between;

  .blue-badge{
    background-color: #0B41CD !important;
  }

    owc-badge {
      margin-left: 10px;
    }
  }
}

.status-container {
  padding-top: 48px;

  .first-item-disable-effects .owc-list-item__list-item-container {
    cursor: unset;
    &:hover {
      background-color: #fff;
    }
    &:focus {
      outline: unset;
    }
  }

  .status-list {
    &__item-content {
      display: flex;
      justify-content: space-between;

      owc-badge {
        margin-left: 10px;
      }
    }
  }
}
