.container-dataset-access-request {
  height: 100%;
  background-color: whitesmoke;

  .owc-card__content-wrapper {
    background-color: whitesmoke;
  }

  .owc-workflow-overlay__backdrop-element__overlay-element owc-card {
    background-color: whitesmoke;
  }

  .dapr-confirmation-container {
    margin: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    img {
      max-width: 10vw;
      margin: auto;
    }
    owc-typography {
      margin: auto;
      text-align: center;
    }
    owc-button {
      margin: auto;
      text-align: center;
    }
  }
}

.dapr-header-container {
  padding: 0 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-bottom: 2px solid var(--one-color-background-intensity-medium);

  .header-text {
    vertical-align: top;
    display: flex;
    align-items: center;
    justify-content: center;
    .close-icon {
      margin-right: 5px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.dapr-footer-container {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-top: 2px solid var(--one-color-background-intensity-low);
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: white;
}
