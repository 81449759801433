@import '~@one/roche-font/roche-font.css';
@import '~@one/web-components/dist/owc/owc.css';
@import '~@one/icons/dist/one-icons-outlined.css';
// @import '~@one/icons/dist/one-icons-filled.css';
// Also you can use legacy dark theme, cobas light theme, cobas dark theme or navify light theme
@import '~@one/design-tokens/css/cobas/light/variables.css';
@import "projects/datacatalog/src/app/data-catalog-home/containers/data-access-approval-request/data-access-approval-request.component.scss";
@import "projects/datacatalog/src/app/data-catalog-home/containers/data-access-download-request/data-access-download-request.component.scss";
@import "projects/datacatalog/src/app/data-catalog-search/data-catalog-search.component.scss";
// @import 'projects/datacatalog/src/app/data-catalog-search/components/compose-queries-component/components/query/query.component.scss';
// @import 'projects/datacatalog/src/app/data-catalog-search/components/select-attributes-component/select-attributes.component.scss';
// @import 'projects/datacatalog/src/app/data-catalog-search/components/select-attributes-component/components/selected-attributes-component/selected-attributes.component.scss';
// @import 'projects/datacatalog/src/app/data-catalog-search/components/select-attributes-component/components/metadata-attributes-tabs/metadata-attributes-tabs.component.scss';