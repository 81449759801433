@import "~@one/roche-font/roche-font.css";
@import "~@one/web-components/dist/owc/owc.css";
@import "~@one/icons/dist/one-icons-outlined.css";
// @import '~@one/icons/dist/one-icons-filled.css';
// Also you can use legacy dark theme, cobas light theme, cobas dark theme or navify light theme
@import "~@one/design-tokens/css/cobas/light/variables.css";
@import './app/components/inbox/inbox.component.scss';
@import './app/modules/overview/components/dataset-datatable/dataset-datatable.component.scss';
@import './app/modules/overview/components/dataset-datatable/components/side-menu/side-menu.component.scss';
@import './app/modules/overview/containers/dataset-request-details/components/review-dataset-drawer/review-dataset-drawer.component.scss';
@import './app/modules/overview/containers/dataset-request-details/components/review-dataset-drawer/components/data-files-preview/data-files-preview.component.scss';
@import './app/modules/overview/containers/dataset-request-details/components/review-data-access-request/review-data-access-request.component.scss';
@import './app/modules/overview/containers/dataset-request-details/components/review-data-download-request/review-data-download-request.component.scss';
@import './app/modules/overview/containers/dataset-request-details/components/review-data-access-request/components/provide-reason/provide-reason.component.scss';


